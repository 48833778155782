import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0 2rem 0 1rem;
  position: relative;
`;

const Table = styled.table`
  font-family: 'Muli';
  width: 100%;
`;

const TableHead = styled.thead`
  td {
    font-size: 1.25rem;
    font-weight: 200;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    text-align: right;
    white-space: nowrap;
  }
`;

const TableBody = styled.tbody`
  tr:nth-child(odd) {
    background-color: #e4e4e4;
  }
`;

const Row = styled.tr`
  width: 100%;
`;

const Cell = styled.td`
  font-weight: 200;
  font-size: 1.25rem;
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
  text-align: right;
  &:last-child {
    text-align: center;
  }

  @media (min-width: 640px) {
    padding: 0.25rem;
  }
`;

const AddButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  width: 4rem;
  svg {
    fill: #2d8db6;
  }
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    outline: none;
  }
  &:active {
    top: 1px;
  }
`;

const NotFound = styled.div`
  min-width: 18rem;
`;

const Message = styled.h3`
  color: #dfdfdf;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
`;

const PricingTable = ({ selectedItems = [], handleOnAdd }) => (
  <Container>
    {selectedItems && selectedItems.length > 0 ? (
      <Table>
        <TableHead>
          <tr>
            <td>Quantity</td>
            <td>Unit Price</td>
            <td>Total Price</td>
          </tr>
        </TableHead>
        <TableBody>
          {selectedItems.map((item, rowIndex) => (
            <Row key={rowIndex}>
              <Cell>{item.Quantity}</Cell>
              <Cell>
                <strong>{item.Unit_Price}</strong>
              </Cell>
              <Cell>
                <strong>{item.Total_Price}</strong>
              </Cell>
              <Cell>
                <AddButton onClick={() => handleOnAdd(item)}>
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                </AddButton>
              </Cell>
            </Row>
          ))}
        </TableBody>
      </Table>
    ) : (
      <NotFound>
        <Message>Not available with that configuration.</Message>
      </NotFound>
    )}
  </Container>
);

export default PricingTable;
