export const escapeRegExp = str =>
  // eslint-disable-next-line no-useless-escape
  str && str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

export const toSlug = text =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    // eslint-disable-next-line
    .replace(/[^\w\-]+/g, '')
    // eslint-disable-next-line
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
