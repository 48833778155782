import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import _ from 'lodash';
import { compose, withProps, withState } from 'recompose';
import { graphql, Link } from 'gatsby';
import { toSlug } from '../utils';

import Layout from '../components/layout';
import Disclaimer from '../components/disclaimer';
import PricingTable from '../components/pricingTable';

const Panel = styled.div`
  background-color: #fff;
  flex-basis: 100%;
  flex-shrink: 0;
  flex-wrap: nowrap;
  width: 100%;
`;

const PanelHeading = styled.h2`
  color: #454545;
  font-family: 'Muli';
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2;
  margin: 0;
  padding: 0;

  span {
    font-weight: 200;
    @media (min-width: 640px) {
      display: inline-block;
    }
  }
`;

const PanelHeader = styled.header`
  padding: 1rem;
  margin: 0;

  @media (min-width: 640px) {
    padding: 2rem 1rem 0.5rem;
  }
`;

const PanelContents = styled.div`
  @media (min-width: 640px) {
    display: flex;
  }
`;

const PanelContent = styled.div`
  flex-basis: 33.333%;
  min-width: 33.333%;
  padding: 0 1rem;
`;

const PanelTable = styled.div`
  flex-grow: 1;
  padding: 0 1rem;
`;

const PanelOptions = styled.div`
  flex-basis: 33.333%;
  min-width: 33.333%;
  padding: 0 1rem 2rem 1rem;
`;

const PanelOption = styled.button`
  appearance: none;
  background-color: ${({ active }) => (active ? '#2D8DB6' : '#f2f2f2')};
  border-radius: 0.25rem;
  border: solid 1px ${({ active }) => (active ? '#2D8DB6' : '#d5d5d5')};
  color: ${({ active }) => (active ? '#fff' : '#3e424d')};
  cursor: pointer;
  display: block;
  font-family: 'Muli';
  font-size: 1rem;
  font-weight: 400;
  line-height: 3.5rem;
  margin-bottom: 0.5rem;
  min-height: 3.5rem;
  overflow: hidden;
  padding: 0 1rem;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

const OptionTitle = styled.h3`
  color: #3e424d;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
  margin: 0;
  padding: 0.75rem 0;
  margin-bottom: 0.5rem;
  border: solid 1px transparent;
  white-space: nowrap;
`;

const OptionGroupListing = styled.div`
  padding-bottom: 1rem;
`;

const Footer = styled.footer`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const BackLink = styled.div`
  & a {
    color: #2d8db6;
    text-decoration: none;
  }
`;

const Directions = styled.p`
  color: #cdcdcd;
  padding: 2.5rem 0;
  text-align: center;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  padding: 0 1rem;
  button {
    max-width: 25%;
  }
`;

const ClearButton = styled.button`
  appearance: none;
  border: none;
  color: #2d8db6;
  font-size: 1rem;
  margin-left: 1rem;
  padding: 0;
`;

const ProductPage = ({
  activeItemsOptionsGrouped,
  activeOptionFilters,
  addedItems,
  allFiltersSelected,
  availableOptions,
  currentLocale,
  itemsFilteredByActiveOption,
  optionGroups,
  productGroupKeys,
  productTitle,
  selectedItems,
  setActiveOptionFilters,
}) => (
  <Layout
    Content={({ handleOnAdd, siteData }) => (
      <div>
        <Helmet
          title={`${siteData.site.siteMetadata.title} | ${productTitle}`}
          meta={[
            { name: 'description', content: 'Vistaprint Corporate Pricing' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Panel>
          <PanelHeader>
            <BackLink>
              <Link to={currentLocale}>Go Back</Link>
            </BackLink>

            <PanelHeading>{productTitle}</PanelHeading>
          </PanelHeader>

          {optionGroups.length && (
            <Actions>
              <h3>Options</h3>
              <ClearButton
                onClick={() =>
                  setActiveOptionFilters(
                    createActiveOptionFilters(availableOptions)
                  )
                }
              >
                Clear All Selections
              </ClearButton>
            </Actions>
          )}

          <PanelContents>
            <PanelOptions>
              {optionGroups.map(optionGroup => (
                <OptionGroupListing key={optionGroup.option}>
                  <OptionTitle>{optionGroup.label}</OptionTitle>
                  {optionGroup.options.length > 1 ? (
                    optionGroup.options.map(({ name, value }) => (
                      <PanelOption
                        key={value}
                        onClick={() => {
                          const updated = { ...activeOptionFilters };
                          updated[optionGroup.option] = value;
                          setActiveOptionFilters(updated);
                        }}
                      >
                        {name}
                      </PanelOption>
                    ))
                  ) : (
                    <PanelOption
                      active={
                        optionGroup.options[0] &&
                        optionGroup.options[0].name.length
                      }
                      disabled
                    >
                      {(optionGroup.options[0] &&
                        optionGroup.options[0].name) ||
                        `Select ${optionGroup.label}`}
                    </PanelOption>
                  )}
                </OptionGroupListing>
              ))}
            </PanelOptions>

            <PanelTable>
              <OptionTitle>Pricing</OptionTitle>
              {allFiltersSelected ? (
                <PricingTable
                  addedItems={addedItems}
                  handleOnAdd={handleOnAdd}
                  selectedItems={selectedItems}
                />
              ) : (
                <Directions>Choose options to see pricing</Directions>
              )}
            </PanelTable>
          </PanelContents>
          <Footer>
            {allFiltersSelected &&
              selectedItems &&
              selectedItems.length && (
                <Disclaimer
                  copy={`${selectedItems[0].BB_SKU} ${selectedItems[0].McpSku}`}
                />
              )}
            <Disclaimer />
          </Footer>
        </Panel>
      </div>
    )}
  />
);

export const query = graphql`
  query($slug: String!) {
    productLine: allProductlineCsv(
      filter: { fields: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          Product_Line
          Option_1
          Option_2
          Option_3
          Option_4
          Option_5
          Option_6
          Option_7
          Option_8
        }
      }
    }
    productData: allPricelistCsv(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          BB_SKU
          McpSku
          Product_Name
          Currency
          Locale
          Quantity
          Total_Price
          Unit_Price
          Product_Category
          Product_Line
          Product
          Option_1
          Option_2
          Option_3
          Option_4
          Option_5
          Option_6
          Option_7
          Option_8
          VBU_Mapping
          Fulfiller
          Price_Type
          Status
        }
      }
    }
  }
`;

const createActiveOptionFilters = availableOptions =>
  availableOptions.reduce((opts, current) => {
    // eslint-disable-next-line no-param-reassign
    opts[current] = null;
    return opts;
  }, {});

const enhance = compose(
  withProps(props => {
    const { cartContents, data } = props;

    /*
      These props won't rerender because they come before the withState functions, which is OK.
      Data from GraphQL
    */
    const productLine = data.productLine && data.productLine.edges[0].node;
    const productTitle = productLine.Product_Line;
    const currentLocale =
      typeof window !== 'undefined'
        ? window.location.pathname.split('/')[1]
        : 'us';

    const productData = data.productData && data.productData.edges;

    // Drop any options that won't be used for product category
    const availableOptions =
      productLine &&
      Object.keys(productLine)
        .filter(key => productLine[key] !== 'Ignore')
        .filter(key => key !== 'Product_Line');

    /*
      Create map of products keyed by options turned into a string with escaped characters

      This is super ugly, but very performant. The options are generated from the current root branch,
      and the trie(sort of) is traversed by selecting options and selecting that path as the active state.

      If there are no options once a selection has been made, the next selections will be made automatically
      until it either hits an option with options or hits the end and shows all the products in the table.

      Things to maybe do soon, allow clearing last option selected and go backwards down the tree. Fix selected
      options to show pretty name, not the slug.

      */

    const productTree =
      productData &&
      productData.reduce((tree, current) => {
        /* available options is the active options of this category */
        const availableOptionsPicked = _.pick(current.node, availableOptions);

        /* */
        const productOptions =
          !_.isEmpty(availableOptionsPicked) &&
          Object.values(availableOptionsPicked);

        if (!productOptions.length) {
          return { ...tree, current };
        }

        const paths = productOptions.map((v, i, arr) => ({
          optionName: v,
          optionSlug: toSlug(v),
          optionPath: arr
            .slice(0, i + 1)
            .map(o => toSlug(o))
            .join('.'),
        }));

        // For each path, set with option name and path
        _.each(paths, path =>
          _.set(tree, path.optionPath, {
            ..._.get(tree, path.optionPath),
            ...path,
          })
        );

        // Create final path with Quantity
        const destination = paths.length
          ? `${paths[paths.length - 1].optionPath}.${current.node.Quantity}`
          : `${current.node.Quantity}`;

        // Set node in destination
        _.set(tree, destination, current.node);

        return tree;
      }, {});

    const addedItems =
      cartContents &&
      cartContents.length &&
      cartContents.map(item => `${item.BB_SKU}-${item.Quantity}`);

    return {
      addedItems,
      availableOptions,
      currentLocale,
      productData,
      productLine,
      productTitle,
      productTree,
      ...props,
    };
  }),
  withState('activeOption', 'setActiveOption', null),
  withState(
    'activeOptionFilters',
    'setActiveOptionFilters',
    ({ availableOptions }) => createActiveOptionFilters(availableOptions)
  ),
  withProps(props => {
    const {
      availableOptions,
      activeOptionFilters,
      productTree,
      productLine,
    } = props;

    /** List of currently selected options names
     * @return {array}
     */
    const activeOptions = availableOptions.map(
      option => activeOptionFilters[option]
    );

    const getOptionsFromTree = currentPath => {
      const productTreePath = _.get(productTree, currentPath, productTree);
      const keys =
        productTreePath &&
        Object.keys(productTreePath).filter(o => o !== 'optionName');

      const options =
        keys &&
        keys
          .map(key => ({
            value: key || null,
            name: _.get(productTreePath, [key, 'optionName'], null),
          }))
          .filter(a => a.name)
          .sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true })
          );

      return options;
    };

    const optionGroups = availableOptions.reduce(
      (optionsArray, current, index) => {
        const selected = activeOptions[index];
        const currentPath = optionsArray.map(o => o.selected.value);
        let options = selected
          ? [{ name: selected, value: selected }]
          : getOptionsFromTree(currentPath);

        if (
          optionsArray[index - 1] &&
          optionsArray[index - 1].selected.value === null
        ) {
          options = [
            {
              value: null,
              name: '',
            },
          ];
        }

        return [
          ...optionsArray,
          {
            option: current,
            label: productLine[current],
            options,
            selected:
              options.length === 1
                ? options[0]
                : {
                    value: null,
                    name: null,
                  },
          },
        ];
      },
      []
    );

    const selectedFilters = optionGroups
      .filter(optionGroup => optionGroup.selected.value)
      .map(optionGroup => optionGroup.selected.value);

    const allFiltersSelected =
      selectedFilters.length === availableOptions.length;

    const currentSelectedFilters = _.get(productTree, selectedFilters);
    const selectedItems =
      allFiltersSelected &&
      !_.isEmpty(currentSelectedFilters) &&
      Object.values(currentSelectedFilters).filter(
        value => typeof value === 'object'
      );

    return {
      activeOptionFilters,
      availableOptions,
      productTree,
      optionGroups,
      allFiltersSelected,
      selectedItems,
      ...props,
    };
  })
);

export default enhance(ProductPage);
